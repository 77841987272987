
/**
 * {
 *      deviceType: 'BLA',
 *      deviceManufacturer: 'Apple',
 *      deviceName: 'iPhone 11 XS MAX 256GB'
 *      deviceColor: '#FFF',
 *      deviceMmid: 18256,
 *      devicePriceCash: 20000,
 *      deviceInstalments: 12,
 *      deviceParticipation: 10000,
 *      deviceMonthlyFee: 2000,
 *      deviceImage: 'https://www.vipmobile.rs/upload/devices_thumbs/AD_17294/AD_17294_image001_200_300.png',
 *  }
 * @type {null}
 * @private
 */
class Device {
    constructor() {
        this._deviceType = null;
        this._deviceCode = null;
        this._deviceManufacturer = null;
        this._deviceName = null;
        this._deviceColor = null;
        this._deviceMmid = null;
        this._devicePriceCash = null;
        this._deviceInstalments = null;
        this._deviceParticipation = null;
        this._deviceMonthlyFee = null;
        this._deviceImage = null;
    }

    isEmpty = () => !this._deviceType &&
        !this._deviceCode &&
        !this._deviceType &&
        !this._deviceManufacturer &&
        !this._deviceName &&
        !this._deviceColor &&
        !this._deviceMmid &&
        !this._devicePriceCash &&
        !this._deviceParticipation &&
        !this._deviceMonthlyFee &&
        !this._deviceImage;

    getAll = () => {
        return {
            deviceType: this._deviceType,
            deviceCode: this._deviceCode,
            deviceManufacturer: this._deviceManufacturer,
            deviceName: this._deviceName,
            deviceColor: this._deviceColor,
            deviceMmid: this._deviceMmid,
            devicePriceCash: this._devicePriceCash,
            deviceInstalments: this._deviceInstalments,
            deviceParticipation: this._deviceParticipation,
            deviceMonthlyFee: this._deviceMonthlyFee,
            deviceImage: this._deviceImage,
        };
    };

    setAll = (type, code, manufacturer, name, color, mmid, price, instalments, participation, montlyFee, image) => {
        this._deviceType = type;
        this._deviceCode = code;
        this._deviceManufacturer = manufacturer;
        this._deviceName = name;
        this._deviceColor = color;
        this._deviceMmid = mmid;
        this._devicePriceCash = price;
        this._deviceInstalments = instalments;
        this._deviceParticipation = participation;
        this._deviceMonthlyFee = montlyFee;
        this._deviceImage = image;
    };

    get deviceType() {
        return this._deviceType;
    }

    set deviceType(value) {
        this._deviceType = value;
    }

    get deviceManufacturer() {
        return this._deviceManufacturer;
    }

    set deviceManufacturer(value) {
        this._deviceManufacturer = value;
    }

    get deviceName() {
        return this._deviceName;
    }

    set deviceName(value) {
        this._deviceName = value;
    }

    get deviceColor() {
        return this._deviceColor;
    }

    set deviceColor(value) {
        this._deviceColor = value;
    }

    get deviceCode() {
        return this._deviceCode;
    }

    get deviceMmid() {
        return this._deviceMmid;
    }

    set deviceMmid(value) {
        this._deviceMmid = value;
    }

    get devicePriceCash() {
        return this._devicePriceCash;
    }

    set devicePriceCash(value) {
        this._devicePriceCash = value;
    }

    get deviceInstalments() {
        return this._deviceInstalments;
    }

    set deviceInstalments(value) {
        this._deviceInstalments = value;
    }

    get deviceParticipation() {
        return this._deviceParticipation;
    }

    set deviceParticipation(value) {
        this._deviceParticipation = value;
    }

    get deviceMonthlyFee() {
        return this._deviceMonthlyFee;
    }

    set deviceMonthlyFee(value) {
        this._deviceMonthlyFee = value;
    }
}

export default Device;
