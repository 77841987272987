import {qs} from "./utils";
import VirtualCart from "./modules/VirtualCart";

const bpo_debug = true

// utils

function eventFire(el, etype) {
    if (el.fireEvent) {
        el.fireEvent('on' + etype);
    } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
    }
}

function handleDoubleAdd(el, dataInfo, attr) {
    if(!$(el).attr('data-booster-bpo')) {
        $(el).attr('data-booster-bpo', dataInfo.boosterBpo)
    } else {
        $(el).attr('data-booster-bpo', $(closestAddToCartCTA).attr('data-booster-bpo') + "," + dataInfo.boosterBpo)
    }
}

function handleDoubleRemove(el, dataInfo, attr) {
    if( $(el).attr(attr).includes(dataInfo) ) {
        var toUpdate = $(el).attr(attr)
        var toUpdate1 = toUpdate.replace(dataInfo, "")
        var toUpdate2 = toUpdate1.replace(",", "")
        $(el).attr(attr, toUpdate2)
    } else {
        $(el).attr(attr, '')
    }
}

function handleCartChangeRadio(thisEl) {
    const $boosterWrappper = $(thisEl).closest('.booster-with-bpo-promo')

    const boosterId = $boosterWrappper.attr('data-booster');
    const boosterPromoPrice = $boosterWrappper.attr('data-booster-promo-price');
    const boosterPrice = $boosterWrappper.attr('data-booster-price');
    const boosterBPOPromo = $boosterWrappper.attr('data-booster-bpo-promo');
    const boosterIsPromo = $boosterWrappper.attr('data-booster-is-promo');
    const boosterValidForSPO = $boosterWrappper.attr('data-booster-bpo-valid-for-spo');
    let boosterBPO = $boosterWrappper.attr('data-booster-bpo');
    const boosterTitle = $boosterWrappper.attr('data-booster-title');

    if(typeof boosterBPO === 'undefined') {
        boosterBPO = $boosterWrappper.attr('data-booster-bpo-promo');
    }

    let arr = [
        boosterId, boosterPromoPrice, boosterPrice, boosterIsPromo, boosterValidForSPO, boosterBPO
    ]

    // const isSelected = !!$(thisEl).find('input').attr('checked');
    // if (isSelected) {
    virtualCart.removeAllBoosterAddons();
    virtualCart.addTariffAddon({ // eslint-disable-line no-undef
        id: boosterId,
        bpo: boosterBPO,
        price: boosterPrice,
        is_booster: true,
        title: boosterTitle,
        props: boosterId,
        bpo_valid_for_SPO: boosterValidForSPO,
        promo_price: boosterPromoPrice,
        bpo_added_with_tariff: virtualCart.tariff.tariffCode,
        bpo_promo: boosterBPOPromo
    });



    if (document.body.classList.contains('pthclass_cart')) {
        // const protectBooster = virtualCart.tariffAddons.filter(singleAddon => singleAddon?.bpo === 'BPO_P_7658;BPO_P_7659');
        // if (protectBooster?.length > 0 && virtualCart.insurance.isSelected) {
        //     virtualCart.toggleInsurance();
        // }
        vcHandler.setInsurance();
        vcHandler.setTotal()
        vcHandler.setTariff()
        vcHandler.setStickyCart()
    }

    const tariffsThatShouldNotHaveInsuranceString = tariffs_that_should_not_have_insurance;
    const tariffsThatShouldNotHaveInsurance = tariffsThatShouldNotHaveInsuranceString.split(',');
    let shouldHideInsuranceForTariffAddon = false;
    //console.log(virtualCart.tariffAddons, 'virtualCart.tariffAddons');
    virtualCart.tariffAddons.forEach(addon => { // eslint-disable-line no-undef
        shouldHideInsuranceForTariffAddon = tariffsThatShouldNotHaveInsurance.includes(addon.bpo) || tariffsThatShouldNotHaveInsurance.includes(addon.bpo_promo);
    })
    //console.log(shouldHideInsuranceForTariffAddon, 'shouldHideInsuranceForTariffAddon');

    if (shouldHideInsuranceForTariffAddon) {
        qs('.insurance-shopping-item', insuranceShoppingItem => {
            insuranceShoppingItem.style.display = 'none';
        });
        qs('.cart-device-promo-bar-insurance', insuranceShoppingItem => {
            insuranceShoppingItem.style.display = 'none';
        });
    } else {
        qs('.insurance-shopping-item', insuranceShoppingItem => {
            insuranceShoppingItem.style.display = '';
        });
        qs('.cart-device-promo-bar-insurance', insuranceShoppingItem => {
            insuranceShoppingItem.style.display = '';
        });
        qs('.insurance-shopping-item .o-shopping-item__content-pricing', item => {
            item.classList.add('display_none');
        })
    }
    //
    //     if (shouldHideInsuranceForTariffAddon) {
    //         qs('.insurance-shopping-item', insuranceShoppingItem => {
    //             insuranceShoppingItem.style.display = 'none';
    //         });
    //         qs('.cart-device-promo-bar-insurance', insuranceShoppingItem => {
    //             insuranceShoppingItem.style.display = 'none';
    //         });
    //         $('.remove-insurance').hide();
    //
    //     } else {
    //         qs('.insurance-shopping-item', insuranceShoppingItem => {
    //             insuranceShoppingItem.style.display = '';
    //         });
    //         qs('.cart-device-promo-bar-insurance', insuranceShoppingItem => {
    //             insuranceShoppingItem.style.display = '';
    //         });
    //         qs('.insurance-shopping-item .o-shopping-item__content-pricing', item => {
    //             item.classList.add('display_none');
    //         })
    //         $('.remove-insurance').hide();
    //
    //
    //         qs('.open-insurance-modal', openInsuranceModalEl => {
    //             if (!openInsuranceModalEl.getAttribute('data-event-added')) {
    //                 openInsuranceModalEl.setAttribute('data-event-added', 'true');
    //                 openInsuranceModalEl.onclick = (e) => {
    //                     qs('#insurance-modal', insuranceModalEl => {
    //                         insuranceModalEl.classList.add('is-on');
    //                     })
    //                     e.preventDefault();
    //                 }
    //             }
    //
    //         });
    //     }
    // }



    // } else {
    //     console.log('remove from cart')
    // }
    // vcHandler.setTotal()
    // vcHandler.setTariff()
}

function handleCartChangeCheckbox(thisEl) {
    const $boosterWrappper = $(thisEl).closest('.booster-without-bpo-promo')


    const boosterId = $boosterWrappper.attr('data-booster');
    const boosterPromoPrice = $boosterWrappper.attr('data-booster-promo-price');
    const boosterPrice = $boosterWrappper.attr('data-booster-price');
    const boosterIsPromo = $boosterWrappper.attr('data-booster-is-promo');
    const boosterValidForSPO = $boosterWrappper.attr('data-booster-bpo-valid-for-spo');
    const boosterBPO = $boosterWrappper.attr('data-booster-bpo');
    const boosterTitle = $boosterWrappper.attr('data-booster-title');

    const isSelected = !!$(thisEl).find('input').attr('checked');
    if (isSelected) {
        virtualCart.addTariffAddon({ // eslint-disable-line no-undef
            id: boosterId,
            bpo: boosterBPO,
            price: boosterPrice,
            is_booster: true,
            title: boosterTitle,
            props: boosterId,
            bpo_valid_for_SPO: boosterValidForSPO,
            promo_price: boosterPromoPrice,
            bpo_added_with_tariff: virtualCart.tariff.tariffCode,
            bpo_promo: boosterIsPromo
        });
    } else {
        virtualCart.removeTariffAddon(boosterBPO)
    }
    if (document.body.classList.contains('pthclass_cart')) {
        vcHandler.setTotal()
        vcHandler.setTariff()
        vcHandler.setStickyCart()
    }

}

function getClosestAddToCartCTA(el) {
    return $(el).closest('.m-tariff-card').find('.tariff-cta-add-to-cart')
}

function getClosestAddToCartCTAChoose(el) {
    return $(el).closest('.m-tariff-card').find('.choose-tariff-cart')
}

function getClosestGoToPLPCTA(el) {
    return $(el).closest('.m-tariff-card').find('.tariff-cta-link')
}

function getClosestFinalPriceWithDevice(el) {
    return $(el).closest('.m-tariff-card').find('.tariff-price-with-device')
}
function getClosestFinalPriceWithDeviceCart(el) {
    return $(el).closest('.m-tariff-card').find('.tariff-price-with-device')
}

function getClosestFinalPriceWithoutDevice(el) {
    return $(el).closest('.m-tariff-card').find('.test-test-1')
}

function getClosestFinalPriceWithoutDeviceCart(el) {
    return $(el).closest('.m-tariff-card').find('.tariff-price-no-device')
}

function getBoosterDataAttributes(el, isPromo) {
    const data = {
        boosterId: $(el).attr('data-booster'),
        boosterPromoPrice: $(el).attr('data-booster-promo-price'),
        boosterPrice: $(el).attr('data-booster-price'),
        boosterValidSpo: $(el).attr('data-booster-bpo-valid-for-spo'),
        boosterBpoPromo: $(el).attr('data-booster-bpo-promo'),
        boosterTitle: $(el).attr('data-booster-title'),
    }
    if(isPromo) {
        data.boosterBpo = $(el).attr('data-booster-bpo-promo')
    } else {
        data.boosterBpo = $(el).attr('data-booster-bpo')
    }
    return data
}

function slideFade(el) {
    const fade = { opacity: 1, transition: 'opacity 400ms' };
    el.css(fade).delay(100).slideUp();
}

function revertSlideFade(el) {
    const fade = { opacity: 1, transition: 'opacity 400ms' };
    el.css(fade).delay(100).slideDown();
}

function handleTabSwitchBooster(currentTab, platinumTemplate, isJqEvent, isAllTariffPage, triggeredManually) {
    var isMobile =  window.matchMedia("(max-width: 768px)").matches;
    var extendedWithTwoBoosters = $('.extendedWithTwoBoosters')
    const siguricaSpecialWrapper = $('.sigurica-single-page-boosters-info')

    if (currentTab === 'tariffTab-1') { // WITH DEVICE

        $('.mnp-info').addClass('visibility_hidden');

        $('.tariffProp-with-device').removeClass('display_none');
        $('.tariffProp-without-device').addClass('display_none');

        $('.tariff-cta-add-to-cart').addClass('display_none');
        $('.tariff-price-no-device').addClass('display_none');
        $('.tariff_monthly_price').addClass('display_none');
        $('.banner_without_device').addClass('display_none');
        $('.tariff-device-price-no-device').addClass('visibility_hidden');

        $('.tariff-cta-link').removeClass('display_none');
        $('.tariff-cta-link-cart').removeClass('display_none');
        $('.tariff-cta-lead').addClass('display_none');

        $('.tariff-price-with-device').removeClass('display_none');
        $('.tariff_monthly_device_price').removeClass('display_none');
        $('.banner_with_device').removeClass('display_none');

        $('.tariff-old-price-no-device').addClass('display_none');
        $('.tariff-old-price-with-device').removeClass('display_none');

        $('.m-tariff-card-flag_image_text').addClass('display_none');

        $('.short-description-with-device').removeClass('display_none')
        $('.short-description-without-device').addClass('display_none')

        $('.special-booster-radio').parent().parent().parent().addClass('display_none_forever')
        // $('.extendedWithTwoBoosters').removeClass('display_none')
        // if($('.extendedWithTwoBoosters').hasClass('display_none')) {
        //     $('.extendedWithTwoBoosters').removeClass('display_none')
        // }

        for(var i = 0; i < extendedWithTwoBoosters.length; i++) {
            var item = extendedWithTwoBoosters[i]
            // if($(item).css('display') === 'none') {
            $(item).css('display', 'block')
            $(item).css('height', '210px')
            // }
        }

        $('.extender-two-boosters').addClass('display_none_forever');

        // $('.tariff-card-for-BPO_START_P').find('.tariff-old-price-no-device-fake').addClass('display_none')

        // $('.tariff-old-price-no-device-fake').addClass('display_none')
        $('.pthclass_neo-platinum').find('.tariff-old-price-no-device-fake').removeClass('display_none')
        $('.pthclass_neo-platinum').find('.short-description-with-device').removeClass('display_none')

        // if(!isMobile) { //temp
        if(isAllTariffPage) {
            if(platinumTemplate) {
                if(isJqEvent !== true) {
                    if(!$('#tariffSection-2').hasClass('active')) {
                        //console.log("platinum dbg 5", 'tariffSection-3')
                        var desiredSwiper = $("#neoSlider");
                        // console.log($("#neoSlider").find('.tariff-card-for-BPO_NEOPLATINUM_P'), "gledajjz")
                        if($("#neoSlider").find('.tariff-card-for-BPO_NEOPLATINUM_P').length === 0 ) {

                            // $('#neoSlider').append($(platinumTemplate))
                            let swiperElement = swiperInstances['neoSlider']
                            swiperElement.appendSlide(platinumTemplate)
                            // $('.tariff-card-for-BPO_NEOPLATINUM_P').css('position', 'unset')
                            // $('.tariff-card-for-BPO_NEOPLATINUM_P').css('left', 'unset')
                        }
                        // if($('#standardneSlider').hasClass('flickity-enabled')){
                        //     $('#standardneSlider').flickity('insert', $(platinumTemplate), 4)
                        // }
                        // if($('#neoSlider').hasClass('flickity-enabled')){
                        //     $('#neoSlider').flickity('insert', $(platinumTemplate), 2)
                        // }
                    }
                }
            }
        }
        // }


        $('.platinum-cta-to-display').removeClass('display_none')
        $('.platinum-cta-to-hide').addClass('display_none')

        $('.booster-prop').addClass('display_none_forever')


        if(!triggeredManually) {
            // DL
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'tariff_device_toggle',
                'device_toggle': 'sa uredjajem'
            });
        }
        $('.switch-with-without-device').data('triggeredManually', false);


        // Sigurica 2.0 tuning
        if(siguricaSpecialWrapper.length !== 0) {
            $('.sigurica-sp-with-device').removeClass('display_none')
            $('.sigurica-sp-no-device').addClass('display_none')
        }

        $('.tariff-card-for-BPO_SIGURICA2').find('.boosters_without_device').addClass('display_none')


        $('.tariff-card-for-BPO_START_P').find('.booster-without-bpo-promo').addClass('display_none')
        $('.tariff-card-for-BPO_NEOPLATINUM_P').removeClass('display_none')

    } else { // WITHOUT DEVICE
        var platinumSelector = $('.tariff-card-for-BPO_NEOPLATINUM_P');

        $('.mnp-info').removeClass('visibility_hidden');

        $('.tariffProp-with-device').addClass('display_none');
        $('.tariffProp-without-device').removeClass('display_none');

        $('.tariff-cta-add-to-cart').removeClass('display_none');
        $('.tariff-price-no-device').removeClass('display_none');
        $('.tariff_monthly_price').removeClass('display_none');
        $('.banner_without_device').removeClass('display_none');

        $('.tariff-cta-link').addClass('display_none');
        $('.tariff-cta-link-cart').addClass('display_none');
        $('.tariff-cta-lead').removeClass('display_none');
        $('.tariff-price-with-device').addClass('display_none');
        $('.tariff_monthly_device_price').addClass('display_none');
        $('.banner_with_device').addClass('display_none');

        $('.tariff-old-price-no-device').removeClass('display_none');
        $('.tariff-old-price-with-device').addClass('display_none');

        $('.m-tariff-card-flag_image_text').removeClass('display_none');
        $('.tariff-device-price-no-device').removeClass('visibility_hidden');

        $('.short-description-with-device').addClass('display_none')
        $('.short-description-without-device').removeClass('display_none')

        $('.special-booster-radio').parent().parent().parent().removeClass('display_none_forever')

        $('.tariff-old-price-no-device-fake').removeClass('display_none')


        // $('.extendedWithTwoBoosters').addClass('display_none')
        $('.extender-two-boosters').removeClass('display_none_forever');
        // if($('.extender-two-boosters').hasClass('active-arrow-extender')) {
        //     $('.extendedWithTwoBoosters').removeClass('display_none')
        // }

        for(var j = 0; j < extendedWithTwoBoosters.length; j++) {
            var itemNoDevice = extendedWithTwoBoosters[j]
            // if($(itemNoDevice).css('display') === 'block') {
            $(itemNoDevice).css('display', 'none')
            $(itemNoDevice).css('height', '280px')
            // }
        }

        $('.extender-two-boosters').removeClass('active-arrow-extender')
        $('.extender-two-boosters').find("img").css('transform', 'rotate(0deg)')
        $('.tariff-card-for-BPO_NEOPLATINUM_P').addClass('display_none')

        if(isAllTariffPage) {
            // $('#standardneSlider').flickity('remove', $(platinumSelector))
            $('#neoSlider').flickity('remove', $(platinumSelector))
        }

        if(!triggeredManually) {
            // DL
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'tariff_device_toggle',
                'device_toggle': 'bez uredjaja'
            });
        }
        $('.switch-with-without-device').data('triggeredManually', false);


        // if(!isMobile) {
        //     if(isAllTariffPage) {
        //         // $('#neoSlider').find('.tariff-card-for-BPO_NEOPLATINUM_P').remove()
        //         let swiperInstance = window.swiperInstances['neoSlider']
        //         let slideToRemove = $('#neoSlider').find('.tariff-card-for-BPO_NEOPLATINUM_P').parent()
        //         swiperInstance.removeSlide($(slideToRemove).index());
        //         if($('#neoSlider').find('.tariff-card-for-BPO_NEOPLATINUM_P').parent().length === 0) {
        //             // ok
        //         } else {
        //             // force remove
        //             $(slideToRemove).remove()
        //         }
        //         swiperInstance.update();
        //     }
        // }
        $('.booster-prop').removeClass('display_none_forever')

        // Sigurica tuning
        if(siguricaSpecialWrapper.length !== 0) {
            $('.sigurica-sp-with-device').addClass('display_none')
            $('.sigurica-sp-no-device').removeClass('display_none')
        }
        $('.tariff-card-for-BPO_SIGURICA2').find('.boosters_without_device').removeClass('display_none')

        $('.tariff-card-for-BPO_START_P').find('.booster-without-bpo-promo').removeClass('display_none')

    }
}

function handleBlueArrow(el) {
    $(el).addClass('active-arrow-extender')
    $(el).css('background-color', 'black')
    $(el).find('.arrow-icon').css('transform','rotate(180deg)');
}

function handleBlackArrow(el) {
    $(el).removeClass('active-arrow-extender')
    $(el).css('background-color', 'black')
    $(el).find('.arrow-icon').css('transform','rotate(0)');
}

function formatPrice(price) {
    let nf = new Intl.NumberFormat('en-US')
    let formatted = nf.format(price)
    return formatted.replace(",", ".")
}

function initBoostersProps(el, id) {
    let propsWrapper = $(el).closest('.m-tariff-card').find('.m-tariff-card__specs-text')
    let props = getBoosterProperties(el, id)
    for (let i = 0; i < props.length; i++) {
        const item = props[i]
        $(propsWrapper).append(item)
    }
}

function getPropertyTemplate(text, id, input) {
    let refreshClass = ''
    if(input) {
        refreshClass = 'refresh'
    }
    return `<div class="m-04-text-with-icon booster-prop booster-${id} ${refreshClass}" data-booster-text="${text}"><img src="https://cdn.a1.rs/a1website/img/a1/icons/icon-check-black.svg" alt="Check Mark" width="11px" height="8px"><span class="a-01-text__primary--sm ">${text}</span></div>`
}

function getBoosterProperties(el, id, input) {
    const propTexts = []
    const propsDiv = $(el).parent().parent().find(".props")[0]
    const properties = $(propsDiv).children()

    for(var i = 0; i < properties.length; i++) {
        let item = properties[i]
        if($(item).attr('data-prop-text')) {
            let data = getPropertyTemplate($(item).attr('data-prop-text'), id, input)
            propTexts.push(data)
        }
    }
    return propTexts
}

function handleInitCheckboxes(wrapper) {

    for (var i = 0; i < wrapper.length; i++) {

        const singleBoosterWrapper = wrapper[i]
        let firstBooster = $(singleBoosterWrapper).find('[data-booster-selected="1"]')
        if (firstBooster.length === 0) {
            // $('.boosters-shoping-item').find('.boosters_without_device').addClass('display_none')
            firstBooster = $(singleBoosterWrapper).children()[1]
        }


        // Init Handle
        switch ($(firstBooster).hasClass('booster-with-bpo-promo')) {
            case true:

                // with bpo promo

                // Set initial bpo code/s on CTA
                const initDataInfo = getBoosterDataAttributes($(firstBooster), true)
                const firstBoosterCTA = getClosestAddToCartCTA($(firstBooster))
                const firstBoosterCTAPLPLink = getClosestGoToPLPCTA($(firstBooster)) //getClosestGoToPLPCTA
                const firstBoosterCTACartModal = getClosestAddToCartCTAChoose($(firstBooster))


                // $(firstBoosterCTAPLPLink).attr('data-booster-bpo', initDataInfo.boosterBpo)
                // $(firstBoosterCTAPLPLink).attr('data-booster-id', initDataInfo.boosterId)
                // $(firstBoosterCTAPLPLink).attr('data-booster-promo-price', initDataInfo.boosterPromoPrice)
                // $(firstBoosterCTAPLPLink).attr('data-booster-price', initDataInfo.boosterPrice)
                // $(firstBoosterCTAPLPLink).attr('data-booster-valid-for-SPO', initDataInfo.boosterValidSpo)
                // $(firstBoosterCTAPLPLink).attr('data-booster-bpo-promo', initDataInfo.boosterBpoPromo)

                $(firstBoosterCTA).attr('data-booster-bpo', initDataInfo.boosterBpo)
                $(firstBoosterCTA).attr('data-booster-id', initDataInfo.boosterId)
                $(firstBoosterCTA).attr('data-booster-promo-price', initDataInfo.boosterPromoPrice)
                $(firstBoosterCTA).attr('data-booster-price', initDataInfo.boosterPrice)
                $(firstBoosterCTA).attr('data-booster-valid-for-SPO', initDataInfo.boosterValidSpo)
                $(firstBoosterCTA).attr('data-booster-bpo-promo', initDataInfo.boosterBpoPromo)
                $(firstBoosterCTA).attr('data-booster-title', initDataInfo.boosterTitle)

                $(firstBoosterCTACartModal).attr('data-booster-bpo', initDataInfo.boosterBpo)
                $(firstBoosterCTACartModal).attr('data-booster-id', initDataInfo.boosterId)
                $(firstBoosterCTACartModal).attr('data-booster-promo-price', initDataInfo.boosterPromoPrice)
                $(firstBoosterCTACartModal).attr('data-booster-price', initDataInfo.boosterPrice)
                $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO', initDataInfo.boosterValidSpo)
                $(firstBoosterCTACartModal).attr('data-booster-bpo-promo', initDataInfo.boosterBpoPromo)
                $(firstBoosterCTACartModal).attr('data-booster-title', initDataInfo.boosterTitle)

                // $(firstBoosterCTA).attr('data-booster-added_with_tariff', initDataInfo.boosterPrice)

                const miniWrapper = $(firstBooster).find('.js-booster-promo')
                const miniWrapperChild = $(miniWrapper).children()[0]
                const miniWrapperInput = $(miniWrapperChild).find('input')
                $(miniWrapper).addClass('active-booster')
                $(miniWrapperInput).prop('checked', true)

                // Neo23 Feb 2023
                const { device, tariff } = virtualCart.getAll()
                if(tariff.tariffCode === 'BPO_NEO24_P') {
                    if(device.deviceCode) {
                        setTimeout(function() {
                            $(miniWrapperInput).trigger('click')
                        }, 50)
                    }
                }
                break
            case false:
                const { tariffAddons } = virtualCart.getAll();


                if(tariffAddons.length > 1) {
                    tariffAddons.forEach(booster => {
                        $('.t-shopping-cart__wrapper').find('[data-booster='+ booster.id +']').find('.booster-mini-prop-wrapper').addClass('active-booster')
                        $('.t-shopping-cart__wrapper').find('[data-booster='+ booster.id +']').find('#bpo-input').attr('checked', true)
                    })
                } else {
                    const booster = virtualCart.getFirstBooster()
                    if(booster) {
                        $('.t-shopping-cart__wrapper').find('[data-booster='+ booster.state.id +']').find('.booster-mini-prop-wrapper').addClass('active-booster')
                        $('.t-shopping-cart__wrapper').find('[data-booster='+ booster.state.id +']').find('#bpo-input').attr('checked', true)
                    }
                }
                // without bpo promo
                break
            default:
                break
        }
    }
}

// main
export function initBoosters(isAllTariffPage) {

    $('.pthclass_detalji').find('.tariff-card-for-BPO_START_P').find('.sd-second-row-separator').addClass('display_none')
    $('.pthclass_detalji').find('.tariff-card-for-BPO_START_P').find('.sd-wrapper.second-row').addClass('display_none')

    const inputTitleHTML = `
<!--        <div class="booster-card-main-title with-special-separator special-booster-title">-->
            <p class="a-01-text__primary--sm">Pojačaj tarifu</p>
            <div class="tariff-special-separator-horizontal-pdp"></div>
            <span class="munja" data-toggle-drawer="booster-drawer"><img src="https://cdn.a1.rs/a1website/img/a1/icons/booster-separator.svg" width="38px" height="38px"></span>
<!--        </div>-->
    `

    const boostersWithoutBpoPromo = $('.booster-without-bpo-promo')

    for(let b = 0; b < boostersWithoutBpoPromo.length; b++) {
        let item = boostersWithoutBpoPromo[b]
        if($(item).prev().hasClass('booster-card-main-title')) {
            $(item).prev().addClass('with-special-separator special-booster-title')
            $(item).prev().html(inputTitleHTML)
        }
    }
    // $('.booster-without-bpo-promo').prev().addClass('with-special-separator special-booster-title')
    // $('.booster-without-bpo-promo').prev().html(inputTitleHTML)

    //
    // if($('.booster-without-bpo-promo').prev().html() !== 'Izaberi svoj promo dodatak') {
    //
    // } else {
    //     $('.booster-without-bpo-promo').prev().html(inputTitleHTML)
    // }




    if(isAllTariffPage) {
        const platinumSelector = $('.tariff-card-for-BPO_NEOPLATINUM_P').parent();
        // window.savePlatinumHTML = $(platinumSelector)[0].outerHTML
        window.savePlatinumHTML = ''
    }

    const allTariffsWrapper = $('.all-tariffs-wrapper')
    const sliders = $(allTariffsWrapper).find('.o-content-slider')

    if(allTariffsWrapper.length > 0) {
        for (var s = 0; s < sliders.length; s++) {
            const item = sliders[s]
            const boosterWrapper = $(item).find('.boosters_without_device')

            handleInitCheckboxes($(boosterWrapper))
        }
    } else {
        const boosterWrapper = $('.boosters_without_device')
        handleInitCheckboxes($(boosterWrapper))
    }

    // Booster With Promo
    const boosterRadioButtons = $('.special-booster-radio')
    const jsBoosterPromo = $('.js-booster-promo')


    $(boosterRadioButtons).on('change', function(e) {
        const desiredWrapper = $(this).parent()
        $(desiredWrapper).parent().prev().find('.booster-mini-prop-wrapper').removeClass('active-booster')
        $(desiredWrapper).parent().next().find('.booster-mini-prop-wrapper').removeClass('active-booster')
        $(desiredWrapper).parent().prev().find('.booster-mini-prop-wrapper').find(':radio').next().addClass('special-after-0')
        $(desiredWrapper).parent().next().find('.booster-mini-prop-wrapper').find(':radio').next().addClass('special-after-0')
        $(desiredWrapper).addClass('active-booster')

        $(desiredWrapper).find(':radio').next().removeClass('special-after-0')
        $(desiredWrapper).find(':radio').next().addClass('special-after-1')


        const boosterElement = $(desiredWrapper).parent()

        const dataInfo = getBoosterDataAttributes($(boosterElement), true)


        // const closestAddToCartCTA = $(desiredWrapper).closest('.m-tariff-card').find('.tariff-cta-add-to-cart')
        const closestAddToCartCTA = getClosestAddToCartCTA($(desiredWrapper))

        const firstBoosterCTAPLPLink = getClosestGoToPLPCTA($(desiredWrapper)) //getClosestGoToPLPCTA

        const firstBoosterCTACartModal = getClosestAddToCartCTAChoose($(desiredWrapper))

        // $(firstBoosterCTAPLPLink).attr('data-booster-bpo', dataInfo.boosterBpo)
        // $(firstBoosterCTAPLPLink).attr('data-booster-id', dataInfo.boosterId)
        // $(firstBoosterCTAPLPLink).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
        // $(firstBoosterCTAPLPLink).attr('data-booster-price', dataInfo.boosterPrice)
        // $(firstBoosterCTAPLPLink).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
        // $(firstBoosterCTAPLPLink).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)

        // $(closestAddToCartCTA).attr('data-promo-bpo', dataInfo.boosterBpo)
        $(closestAddToCartCTA).attr('data-booster-bpo', dataInfo.boosterBpo)
        $(closestAddToCartCTA).attr('data-booster-id', dataInfo.boosterId)
        $(closestAddToCartCTA).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
        $(closestAddToCartCTA).attr('data-booster-price', dataInfo.boosterPrice)
        $(closestAddToCartCTA).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
        $(closestAddToCartCTA).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
        $(closestAddToCartCTA).attr('data-booster-title', dataInfo.boosterTitle)

        $(firstBoosterCTACartModal).attr('data-booster-bpo', dataInfo.boosterBpo)
        $(firstBoosterCTACartModal).attr('data-booster-id', dataInfo.boosterId)
        $(firstBoosterCTACartModal).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
        $(firstBoosterCTACartModal).attr('data-booster-price', dataInfo.boosterPrice)
        $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
        $(firstBoosterCTACartModal).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
        $(firstBoosterCTACartModal).attr('data-booster-title', dataInfo.boosterTitle)

        // this is for start tariff page
        if (dataInfo.boosterId === '246') {
            $('.start-single-page-boosters-info-text.junior-booster').addClass('active')
            $('.start-single-page-boosters-info-text.senior-booster').removeClass('active')

            $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__secondary-bold--lg')
            $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__primary--lg')
        }
        $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__secondary-bold--lg')
        $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__primary--lg')
        if (dataInfo.boosterId === '247') {
            $('.start-single-page-boosters-info-text.junior-booster').removeClass('active')
            $('.start-single-page-boosters-info-text.senior-booster').addClass('active')


            $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__secondary-bold--lg')
            $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__primary--lg')

            $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__secondary-bold--lg')
            $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__primary--lg')
            //a-01-text__secondary-bold--lg
        }

        $('.booster-prop').addClass('display_none')

        let propsWrapper = $(this).closest('.m-tariff-card').find('.m-tariff-card__specs-text')
        const arePropsAlreadyAdded = $(propsWrapper).find(".booster-" + dataInfo.boosterId).length !== 0;

        if(!arePropsAlreadyAdded) {
            let props = getBoosterProperties(this, dataInfo.boosterId, false)
            for (var i = 0; i < props.length; i++) {
                var item = props[i]
                $(propsWrapper).append(item)
            }
        }


        $(".booster-" + dataInfo.boosterId).removeClass('display_none')

        handleCartChangeRadio(this);

    })

    $(jsBoosterPromo).on('click', function(e) {
        $(this).find('input').trigger('change')
    })

    // Booster Without Promo
    const boosterInputButton = $('.special-booster-input')
    const jsBooster = $('.js-booster')


    $(boosterInputButton).on('change', function(e) {
        const desiredWrapper = $(this).closest('.booster-mini-prop-wrapper')


        const boosterElement = $(desiredWrapper).parent()
        const dataInfo = getBoosterDataAttributes($(boosterElement), false)

        const closestAddToCartCTA = getClosestAddToCartCTA($(desiredWrapper))
        const firstBoosterCTAPLPLink = getClosestGoToPLPCTA($(desiredWrapper)) //getClosestGoToPLPCTA
        const firstBoosterCTACartModal = getClosestAddToCartCTAChoose($(desiredWrapper))


        const closestFinalPriceWithDevice =  getClosestFinalPriceWithDevice($(desiredWrapper)).children()[0]
        const closestFinalPriceWithoutDevice = getClosestFinalPriceWithoutDevice($(desiredWrapper)).children()[0]


        const closestFinalPriceWithDeviceCart =  getClosestFinalPriceWithDeviceCart($(desiredWrapper)).children()[0]
        const closestFinalPriceWithoutDeviceCart = getClosestFinalPriceWithoutDeviceCart($(desiredWrapper)).children()[0]

        const finalPriceWithDevice = $(closestFinalPriceWithDevice).text().split('.').join('')
        const finalPriceWithoutDevice = $(closestFinalPriceWithoutDevice).text().split('.').join('')
        const finalPriceWithDeviceCart = $(closestFinalPriceWithDeviceCart).text().split('.').join('')
        const finalPriceWithoutDeviceCart = $(closestFinalPriceWithoutDeviceCart).text().split('.').join('')

        let isSigurica2 = dataInfo.boosterValidSpo === 'BPO_SIGURICA2';
        let isStart = dataInfo.boosterValidSpo === 'BPO_START_P';


        // $(closestAddToCartCTA).attr('data-booster-bpo', '')
        if($(desiredWrapper).hasClass('active-booster')) {

            if($('body').hasClass('pthclass_start') || $('body').hasClass('pthclass_postpaid_tarife')) {
                // this is for start tariff page
                if (dataInfo.boosterId === '246') {
                    $('.start-single-page-boosters-info-text.junior-booster').removeClass('active')
                    // $('.start-single-page-boosters-info-text.senior-booster').removeClass('active')
                    $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__secondary-bold--lg')
                    $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__primary--lg')
                }
                // else {
                //     $('.start-single-page-boosters-info-text.junior-booster').removeClass('active')
                //     // $('.start-single-page-boosters-info-text.senior-booster').removeClass('active')
                //     $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__secondary-bold--lg')
                //     $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__primary--lg')
                // }

                if (dataInfo.boosterId === '247') {
                    // $('.start-single-page-boosters-info-text.junior-booster').removeClass('active')
                    $('.start-single-page-boosters-info-text.senior-booster').removeClass('active')
                    $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__secondary-bold--lg')
                    $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__primary--lg')
                }
                // else {
                //     $('.start-single-page-boosters-info-text.senior-booster').removeClass('active')
                //     $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__secondary-bold--lg')
                //     $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__primary--lg')
                // }

            }

            $(this).find('input').attr('checked', false)
            $(desiredWrapper).removeClass('active-booster')

            if($(closestAddToCartCTA).length >= 1) {
                // $(closestAddToCartCTA).attr('data-booster-bpo', '')
                handleDoubleRemove(closestAddToCartCTA, dataInfo.boosterBpo, 'data-booster-bpo');
                // $(closestAddToCartCTA).attr('data-booster-id', '')
                handleDoubleRemove(closestAddToCartCTA, dataInfo.boosterId, 'data-booster-id');
                // $(closestAddToCartCTA).attr('data-booster-promo-price', '')
                handleDoubleRemove(closestAddToCartCTA, dataInfo.boosterPromoPrice, 'data-booster-promo-price');
                // $(closestAddToCartCTA).attr('data-booster-price', '')
                handleDoubleRemove(closestAddToCartCTA, dataInfo.boosterPrice, 'data-booster-price');
                // $(closestAddToCartCTA).attr('data-booster-valid-for-SPO', '')
                handleDoubleRemove(closestAddToCartCTA, dataInfo.boosterValidSpo, 'data-booster-valid-for-SPO');
                $(closestAddToCartCTA).attr('data-booster-bpo-promo', '')
                // handleDoubleRemove(closestAddToCartCTA, dataInfo, 'data-booster-bpo-promo');
                $(closestAddToCartCTA).attr('data-booster-title', '')
            }

            if(!isSigurica2) {
                $(firstBoosterCTAPLPLink).attr('data-booster-bpo', '')
                $(firstBoosterCTAPLPLink).attr('data-booster-id', '')
                $(firstBoosterCTAPLPLink).attr('data-booster-promo-price', '')
                $(firstBoosterCTAPLPLink).attr('data-booster-price', '')
                $(firstBoosterCTAPLPLink).attr('data-booster-valid-for-SPO', '')
                $(firstBoosterCTAPLPLink).attr('data-booster-bpo-promo', '')
                $(firstBoosterCTAPLPLink).attr('data-booster-title', '')
            }


            if($(firstBoosterCTACartModal).length >= 1) {
                // $(firstBoosterCTACartModal).attr('data-booster-bpo', '')
                handleDoubleRemove(firstBoosterCTACartModal, dataInfo.boosterBpo, 'data-booster-bpo');
                // $(firstBoosterCTACartModal).attr('data-booster-id', '')
                handleDoubleRemove(firstBoosterCTACartModal, dataInfo.boosterId, 'data-booster-id');
                // $(firstBoosterCTACartModal).attr('data-booster-promo-price', '')
                handleDoubleRemove(firstBoosterCTACartModal, dataInfo.boosterPromoPrice, 'data-booster-promo-price');
                // $(firstBoosterCTACartModal).attr('data-booster-price', '')
                handleDoubleRemove(firstBoosterCTACartModal, dataInfo.boosterPrice, 'data-booster-price');
                // $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO', '')
                handleDoubleRemove(firstBoosterCTACartModal, dataInfo.boosterValidSpo, 'data-booster-valid-for-SPO');
                $(firstBoosterCTACartModal).attr('data-booster-bpo-promo', '')
                $(firstBoosterCTACartModal).attr('data-booster-title', '')
                // handleDoubleRemove(firstBoosterCTACartModal, dataInfo.boosterBpoPromo, 'data-booster-bpo-promo');
                $(closestAddToCartCTA).attr('data-booster-title', '')
                handleDoubleRemove(firstBoosterCTACartModal, dataInfo.boosterTitle, 'data-booster-title');
            }


            const calcFinalPriceWithDevice = parseInt(finalPriceWithDevice) - parseInt(dataInfo.boosterPrice)
            const calcFinalPriceWithoutDevice = parseInt(finalPriceWithoutDevice) - parseInt(dataInfo.boosterPrice)

            const calcFinalPriceWithDeviceCart = parseInt(finalPriceWithDeviceCart) - parseInt(dataInfo.boosterPrice)
            const calcFinalPriceWithoutDeviceCart = parseInt(finalPriceWithoutDeviceCart) - parseInt(dataInfo.boosterPrice)

            if(!isSigurica2 && !isStart) {
                $(closestFinalPriceWithDevice).text(formatPrice(calcFinalPriceWithDevice))
            }
            $(closestFinalPriceWithoutDevice).text(formatPrice(calcFinalPriceWithoutDevice))

            if(!isSigurica2 && !isStart) {
                $(closestFinalPriceWithDeviceCart).text(formatPrice(calcFinalPriceWithDeviceCart))
            }
            $(closestFinalPriceWithoutDeviceCart).text(formatPrice(calcFinalPriceWithoutDeviceCart))

            // DL
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'tariff_addon_deselect',
                'addon_name': dataInfo.boosterTitle
            });
        } else {

            if($('body').hasClass('pthclass_start') || $('body').hasClass('pthclass_postpaid_tarife')) {
                // this is for start tariff page
                if (dataInfo.boosterId === '246') {
                    $('.start-single-page-boosters-info-text.junior-booster').addClass('active')
                    // $('.start-single-page-boosters-info-text.senior-booster').removeClass('active')
                    $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__secondary-bold--lg')
                    $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__primary--lg')
                }
                // else {
                //     $('.start-single-page-boosters-info-text.junior-booster').removeClass('active')
                //     // $('.start-single-page-boosters-info-text.senior-booster').removeClass('active')
                //     $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__secondary-bold--lg')
                //     $('.start-single-page-boosters-info-text.junior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__primary--lg')
                // }

                if (dataInfo.boosterId === '247') {
                    // $('.start-single-page-boosters-info-text.junior-booster').removeClass('active')
                    $('.start-single-page-boosters-info-text.senior-booster').addClass('active')
                    $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__secondary-bold--lg')
                    $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__primary--lg')
                }
                // else {
                //     $('.start-single-page-boosters-info-text.senior-booster').removeClass('active')
                //     $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').removeClass('a-01-text__secondary-bold--lg')
                //     $('.start-single-page-boosters-info-text.senior-booster .start-single-page-boosters-info-title-part').addClass('a-01-text__primary--lg')
                // }

            }

            $(this).find('input').attr('checked', true)
            $(desiredWrapper).addClass('active-booster')

            if(!isSigurica2) {
                $(firstBoosterCTAPLPLink).attr('data-booster-bpo', dataInfo.boosterBpo)
                $(firstBoosterCTAPLPLink).attr('data-booster-id', dataInfo.boosterId)
                $(firstBoosterCTAPLPLink).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
                $(firstBoosterCTAPLPLink).attr('data-booster-price', dataInfo.boosterPrice)
                $(firstBoosterCTAPLPLink).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
                $(firstBoosterCTAPLPLink).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
                $(firstBoosterCTAPLPLink).attr('data-booster-title', dataInfo.boosterTitle)
            }


            $(closestAddToCartCTA).attr('data-promo-bpo', dataInfo.boosterBpo)
            // $(closestAddToCartCTA).attr('data-booster-bpo', dataInfo.boosterBpo)
            if(!$(closestAddToCartCTA).attr('data-booster-bpo')) {
                $(closestAddToCartCTA).attr('data-booster-bpo', dataInfo.boosterBpo)
            } else {
                $(closestAddToCartCTA).attr('data-booster-bpo', $(closestAddToCartCTA).attr('data-booster-bpo') + "," + dataInfo.boosterBpo)
            }
            // $(closestAddToCartCTA).attr('data-booster-id', dataInfo.boosterId)
            if(!$(closestAddToCartCTA).attr('data-booster-id')) {
                $(closestAddToCartCTA).attr('data-booster-id', dataInfo.boosterId)
            } else {
                $(closestAddToCartCTA).attr('data-booster-id', $(closestAddToCartCTA).attr('data-booster-id') + "," + dataInfo.boosterId)
            }
            // $(closestAddToCartCTA).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
            if(!$(closestAddToCartCTA).attr('data-booster-promo-price')) {
                $(closestAddToCartCTA).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
            } else {
                $(closestAddToCartCTA).attr('data-booster-promo-price', $(closestAddToCartCTA).attr('data-booster-promo-price') + "," + dataInfo.boosterPromoPrice)
            }
            // $(closestAddToCartCTA).attr('data-booster-price', dataInfo.boosterPrice)
            if(!$(closestAddToCartCTA).attr('data-booster-price')) {
                $(closestAddToCartCTA).attr('data-booster-price', dataInfo.boosterPrice)
            } else {
                $(closestAddToCartCTA).attr('data-booster-price', $(closestAddToCartCTA).attr('data-booster-price') + "," + dataInfo.boosterPrice)
            }
            // $(closestAddToCartCTA).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
            if(!$(closestAddToCartCTA).attr('data-booster-valid-for-SPO')) {
                $(closestAddToCartCTA).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
            } else {
                $(closestAddToCartCTA).attr('data-booster-valid-for-SPO', $(closestAddToCartCTA).attr('data-booster-valid-for-SPO') + "," + dataInfo.boosterValidSpo)
            }
            // $(closestAddToCartCTA).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
            if(!$(closestAddToCartCTA).attr('data-booster-bpo-promo')) {
                $(closestAddToCartCTA).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
            } else {
                $(closestAddToCartCTA).attr('data-booster-bpo-promo', $(closestAddToCartCTA).attr('data-booster-valid-for-SPO') + "," + dataInfo.boosterBpoPromo)
            }
            if(!$(closestAddToCartCTA).attr('data-booster-title')) {
                $(closestAddToCartCTA).attr('data-booster-title', dataInfo.boosterTitle)
            } else {
                $(closestAddToCartCTA).attr('data-booster-title', $(closestAddToCartCTA).attr('data-booster-title') + "," + dataInfo.boosterTitle)
            }

            $(closestAddToCartCTA).attr('data-booster-title', dataInfo.boosterTitle)

            $(closestAddToCartCTA).attr('data-booster-title', dataInfo.boosterTitle)


            // $(firstBoosterCTACartModal).attr('data-booster-bpo', dataInfo.boosterBpo)
            if(!$(firstBoosterCTACartModal).attr('data-booster-bpo')) {
                $(firstBoosterCTACartModal).attr('data-booster-bpo', dataInfo.boosterBpo)
            } else {
                $(firstBoosterCTACartModal).attr('data-booster-bpo', $(firstBoosterCTACartModal).attr('data-booster-bpo') + "," + dataInfo.boosterBpo)
            }
            // $(firstBoosterCTACartModal).attr('data-booster-id', dataInfo.boosterId)
            if(!$(firstBoosterCTACartModal).attr('data-booster-id')) {
                $(firstBoosterCTACartModal).attr('data-booster-id', dataInfo.boosterId)
            } else {
                $(firstBoosterCTACartModal).attr('data-booster-id', $(firstBoosterCTACartModal).attr('data-booster-id') + "," + dataInfo.boosterId)
            }
            // $(firstBoosterCTACartModal).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
            if(!$(firstBoosterCTACartModal).attr('data-booster-promo-price')) {
                $(firstBoosterCTACartModal).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
            } else {
                $(firstBoosterCTACartModal).attr('data-booster-promo-price', $(firstBoosterCTACartModal).attr('data-booster-promo-price') + "," + dataInfo.boosterPromoPrice)
            }
            // $(firstBoosterCTACartModal).attr('data-booster-price', dataInfo.boosterPrice)
            if(!$(firstBoosterCTACartModal).attr('data-booster-price')) {
                $(firstBoosterCTACartModal).attr('data-booster-price', dataInfo.boosterPrice)
            } else {
                $(firstBoosterCTACartModal).attr('data-booster-price', $(firstBoosterCTACartModal).attr('data-booster-price') + "," + dataInfo.boosterPrice)
            }
            // $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
            if(!$(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO')) {
                $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
            } else {
                $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO', $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO') + "," + dataInfo.boosterValidSpo)
            }
            // $(firstBoosterCTACartModal).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
            if(!$(firstBoosterCTACartModal).attr('data-booster-bpo-promo')) {
                $(firstBoosterCTACartModal).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
            } else {
                $(firstBoosterCTACartModal).attr('data-booster-bpo-promo', $(firstBoosterCTACartModal).attr('data-booster-bpo-promo') + "," + dataInfo.boosterBpoPromo)
            }
            $(firstBoosterCTACartModal).attr('data-booster-title', dataInfo.boosterTitle)

            const calcFinalPriceWithDevice = parseInt(finalPriceWithDevice) + parseInt(dataInfo.boosterPrice)
            const calcFinalPriceWithoutDevice = parseInt(finalPriceWithoutDevice) + parseInt(dataInfo.boosterPrice)

            const calcFinalPriceWithDeviceCart = parseInt(finalPriceWithDeviceCart) + parseInt(dataInfo.boosterPrice)
            const calcFinalPriceWithoutDeviceCart = parseInt(finalPriceWithoutDeviceCart) + parseInt(dataInfo.boosterPrice)

            if(!isSigurica2 && !isStart) {
                $(closestFinalPriceWithDevice).text(formatPrice(calcFinalPriceWithDevice))
            }
            $(closestFinalPriceWithoutDevice).text(formatPrice(calcFinalPriceWithoutDevice))

            if(!isSigurica2 && !isStart) {
                $(closestFinalPriceWithDeviceCart).text(formatPrice(calcFinalPriceWithDeviceCart))
            }
            $(closestFinalPriceWithoutDeviceCart).text(formatPrice(calcFinalPriceWithoutDeviceCart))

            // DL
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'tariff_addon_select',
                'addon_name': dataInfo.boosterTitle
            });
        }



        let propsWrapper = $(this).closest('.m-tariff-card').find('.m-tariff-card__specs-text')
        if($(propsWrapper).hasClass('extendedWithTwoBoosters')) {

            const arePropsAlreadyAdded = $(propsWrapper).find(".booster-" + dataInfo.boosterId).length !== 0;


            if(!arePropsAlreadyAdded) {
                let props = getBoosterProperties(this, dataInfo.boosterId, true)
                for (var i = 0; i < props.length; i++) {
                    var item = props[i]
                    $(propsWrapper).append(item)
                }
            } else {
                let id = $(this).parent().parent().attr('data-booster')
                $('*').removeClass('display_none_duplicate')

                $('.booster-prop.booster-'+id).remove()
            }
        }


        let addedProps = $(propsWrapper).find('.booster-prop')
        for(let j = 0; j < addedProps.length; j++) {
            let item = addedProps[j]
            let name = $(item).attr('data-booster-text')

            let findItems = $('*[data-booster-text="' + name +'"]')
            if(findItems.length === 2) {
                let secondItem = $(findItems)[1]
                $(secondItem).addClass('display_none_duplicate')
                // $(findItems)[1]
            }

            // if(findItems.length > 1) {
            //     $(findItems)[1].remove()
            // }

        }

        // const isSelected = !!$(this).find('input').attr('checked');
        handleCartChangeCheckbox(this);
        // setAddonsTotalPrice
    })

    $(jsBooster).on('click', function(e) {
        e.preventDefault()
        $(this).find('input').trigger('change')
    })




    // Arrow logic

    const allArrows = $('.arrow-extender')
    for (let a = 0; a < allArrows.length; a++) {
        const item = allArrows[a]
        const cardSpecsText = $(item).parent().parent().parent().parent().find('.m-tariff-card__specs-text')
        if($(cardSpecsText).hasClass('extendedWithTwoBoosters')) {
            // $(cardSpecsText).addClass('display_none')
        }
        if($(cardSpecsText).hasClass('extendedWithOneBooster') && !$(cardSpecsText).hasClass('extendedWithOneBoosterAndMoreThanThreeProps')) {
            $(item).addClass('display_none')
        }
        if($(cardSpecsText).hasClass('isWithDeviceSelectedClass')) {
            // $(cardSpecsText).addClass('display_none')
            $(item).removeClass('display_none')
            $(cardSpecsText).removeClass('extendedWithTwoBoosters')
            $(cardSpecsText).removeClass('extendedWithOneBoosterAndMoreThanThreeProps')
            $(cardSpecsText).removeClass('extendedWithOneBooster')
            $(cardSpecsText).removeClass('withoutBooster')
        }
    }

    $(allArrows).on('click', function(e) {

        let cta = $(e.currentTarget).closest('.m-tariff-card').find('.tariff-cta-add-to-cart');
        // let tariffMonthly = cta.getAttribute('data-tariff-monthly');
        let tariffMonthly = $(cta).attr('data-tariff-monthly');
        let tariffOldPriceNoDevice = $(cta).attr('data-tariff-old-price-no-device');

        var cardSpecsText = $(this).parent().parent().parent().parent().find('.m-tariff-card__specs-text')


        // CASE 1: extendedWithTwoBooster
        if($(cardSpecsText).hasClass('extendedWithTwoBoosters')) {

            if(!$(this).hasClass('active-arrow-extender')) {
                handleBlueArrow($(this))
                // $(cardSpecsText).removeClass('display_none')
                $(cardSpecsText).slideDown(200);
                // $(cardSpecsText).parent().parent().find('.boosters_without_device').addClass('display_none')
            } else{
                handleBlackArrow($(this))
                // $(cardSpecsText).addClass('display_none')
                $(cardSpecsText).slideUp();
                // $(cardSpecsText).parent().parent().find('.boosters_without_device').removeClass('display_none')
            }
        }
        // CASE 2: extendedWithOneBoosterAndMoreThanThreeProps
        else if($(cardSpecsText).hasClass('extendedWithOneBoosterAndMoreThanThreeProps')) {

            if(!$(this).hasClass('active-arrow-extender')) {
                handleBlueArrow($(this))
                $(cardSpecsText).find('.additional-tariff-props').removeClass('display_none')
                $(cardSpecsText).parent().parent().find('.boosters_without_device').addClass('display_none')
            } else{
                handleBlackArrow($(this))
                $(cardSpecsText).find('.additional-tariff-props').addClass('display_none')
                $(cardSpecsText).parent().parent().find('.boosters_without_device').removeClass('display_none')
            }
            // $(cardSpecsText).removeClass('extendedWithOneBoosterAndMoreThanThreeProps')
        }
        // CASE 3: isWithDeviceSelectedClass used ONLY on PDP/CART modal
        else if($(cardSpecsText).hasClass('isWithDeviceSelectedClass')) {

            if(!$(this).hasClass('active-arrow-extender')) {
                handleBlueArrow($(this))
                $(cardSpecsText).slideDown(200);

                // $(cardSpecsText).removeClass('display_none')
                // $(cardSpecsText).parent().parent().parent().find('.modal-prices-tariff-wrapper').addClass('display_none')
                // $(cardSpecsText).parent().parent().parent().find('.sd-second-row-separator').removeClass('display_none')
                // $(cardSpecsText).parent().parent().parent().find('.sd-wrapper.second-row').removeClass('display_none')
            } else{
                handleBlackArrow($(this))
                $(cardSpecsText).slideUp();

                // $(cardSpecsText).addClass('display_none')
                // $(cardSpecsText).parent().parent().parent().find('.modal-prices-tariff-wrapper').removeClass('display_none')
                //
                // $(cardSpecsText).parent().parent().parent().find('.sd-second-row-separator').addClass('display_none')
                // $(cardSpecsText).parent().parent().parent().find('.sd-wrapper.second-row').addClass('display_none')
            }
        }
        // CASE DEFAULT
        else {

            if(!$(this).hasClass('active-arrow-extender')) {
                handleBlackArrow($(this))
                slideFade($(cardSpecsText))
            } else{
                handleBlueArrow($(this))
                revertSlideFade($(cardSpecsText))
            }
        }

        // DL

        const isExtended = false;

        const ctaAddToCart = $(this).closest('.m-tariff-card').find('.tariff-cta-add-to-cart').get(0);

        var boosterId = ctaAddToCart.getAttribute('data-booster-id');
        var boosterBPO = ctaAddToCart.getAttribute('data-booster-bpo');
        var boosterPromoPrice = ctaAddToCart.getAttribute('data-booster-promo-price');
        var boosterPrice = ctaAddToCart.getAttribute('data-booster-price');
        var boosterValidForSPO =  ctaAddToCart.getAttribute('data-booster-valid-for-SPO');
        var boosterBpoPromo =  ctaAddToCart.getAttribute('data-booster-bpo-promo');
        var tariffName = ctaAddToCart.getAttribute('data-tariff-name');
        var boosterTitle =  ctaAddToCart.getAttribute('data-booster-title');

        const boosterName = boosterTitle ? boosterTitle : "";

        const attributeData = getTariffCardAttributeWithoutDeviceData(this);
        const internetData = getTariffCardInternetWithoutDeviceData(this);
        // console.log($(this).closest('.m-tariff-card'), 'THIS 1')
        const dlToSend = {
            action: isExtended ? 'Tariff Details Collapse' : 'Tariff Details Expand', // Tariff Details Collapse
            tariffBoosterName: boosterName,
            tariffName: tariffName,
            isPromo: !!boosterBpoPromo,
            internet: internetData?.replace(/\n/g, " "),
            attribute: attributeData?.replace(/\n/g, " "),
            basePrice: tariffOldPriceNoDevice ? tariffOldPriceNoDevice : tariffMonthly,
            finalPrice: tariffMonthly,
            isWithDevice: $('.switch-with-without-device-input').prop('checked'),
            tariffCategory: $('.tariff-section-switcher.active p').text(),
        };

        //console.log(dlToSend, 'dlToSenddlToSenddlToSend collapse')
        boosterDataLayer(dlToSend);
    })

    const switchWithWithoutDevice = $('.switch-with-without-device')

    const tariffTab1 = $('#tariffTab-1') // with device
    const tariffTab2 = $('#tariffTab-2') // without device

    $(switchWithWithoutDevice).on('change', function(e, isJqTrigger) {
        //console.log("ovovovovovovovoovovov")
        const triggeredManually = $(switchWithWithoutDevice).data('triggeredManually');

        if($(this).find('.switch-with-without-device-input').prop('checked')) {
            // $(tariffTab1).trigger('click')
            if(isAllTariffPage) {
                handleTabSwitchBooster('tariffTab-1', window.savePlatinumHTML, isJqTrigger, isAllTariffPage, triggeredManually)
            } else{

                handleTabSwitchBooster('tariffTab-1')
            }
            $(tariffTab1).addClass('active-tab-switcher')
            $(tariffTab2).removeClass('active-tab-switcher')
            $(tariffTab1).find('p').removeClass('a-01-text__primary--lg')
            $(tariffTab1).find('p').addClass('a-01-text__primary-bold--lg')
            $(tariffTab2).find('p').removeClass('a-01-text__primary-bold--lg')
            $(tariffTab2).find('p').addClass('a-01-text__primary--lg')

            //this is for start tariff page
            $('.start-sp-with-device').removeClass('display_none')
            $('.start-sp-no-device').addClass('display_none')
            $('.start_tariff_page_without_device_baner').addClass('display_none')
            $('.start_tariff_page_with_device_baner').removeClass('display_none')

            $(".m-tariff-card__cta-link").find('a').each(function() {
                var href = $(this).attr('href');
                href = href.replace(/([?&])withDevice=\d/, '$1withDevice=1');
                $(this).attr('href', href);
            });
        } else{
            // $(tariffTab2).trigger('click')
            // handleTabSwitchBooster('tariffTab-2')
            if(isAllTariffPage) {
                handleTabSwitchBooster('tariffTab-2', window.savePlatinumHTML, isJqTrigger, isAllTariffPage, triggeredManually)
            } else{
                handleTabSwitchBooster('tariffTab-2')
            }
            $(tariffTab1).removeClass('active-tab-switcher')
            $(tariffTab2).addClass('active-tab-switcher')
            $(tariffTab1).find('p').addClass('a-01-text__primary--lg')
            $(tariffTab1).find('p').removeClass('a-01-text__primary-bold--lg')
            $(tariffTab2).find('p').addClass('a-01-text__primary-bold--lg')
            $(tariffTab2).find('p').removeClass('a-01-text__primary--lg')

            //this is for start tariff page
            $('.start-sp-with-device').addClass('display_none')
            $('.start-sp-no-device').removeClass('display_none')
            $('.start_tariff_page_without_device_baner').removeClass('display_none')
            $('.start_tariff_page_with_device_baner').addClass('display_none')


            $(".m-tariff-card__cta-link").find('a').each(function() {
                var href = $(this).attr('href');
                href = href.replace(/([?&])withDevice=\d/, '$1withDevice=0');
                $(this).attr('href', href);
            });
        }
    })

    $('.tariff-tab-switcher').on('click', function(e) {

        if(isAllTariffPage) {
            let isWithDevice = '0';
            if (e.currentTarget.id === "tariffTab-1") {
                window.isWithDevice = true;
                isWithDevice = '1';
            } else if (e.currentTarget.id === "tariffTab-2") {
                window.isWithDevice = false;
                isWithDevice = '0';
            }

            $(".m-tariff-card__cta-link").find('a').each(function() {
                var href = $(this).attr('href');
                href = href.replace(/([?&])withDevice=\d/, '$1withDevice=' + isWithDevice);
                $(this).attr('href', href);
            });
        }

        if($(e.currentTarget).hasClass('active-tab-switcher')){
            return e.preventDefault()
        }

        $(switchWithWithoutDevice).trigger('click')
    })

    const tariffSectionChange = $('.tariff-section-switcher')

    $(tariffSectionChange).on('click',function() {
        const wholeList = [...$('.tariff-card-for-BPO_NEOPLATINUM_P')]


        wholeList.forEach(item => {
            // $('#standardneSlider').flickity('remove', $(item))
            // $('#neoSlider').flickity('remove', $(item))
        })

        $(switchWithWithoutDevice).data('triggeredManually', true);
        $(switchWithWithoutDevice).trigger('change')
    })

    // display initial booster props
    const activeBooster = $('.active-booster')
    for (let i = 0; i < activeBooster.length; i++) {
        const item = activeBooster[i]
        const id = $(item).attr("data-booster")
        initBoostersProps(item, id)
    }
}


export function initBoostersCart() {

    $('.pthclass_cart').find('.tariff-card-for-BPO_START_P').find('.sd-second-row-separator').addClass('display_none')
    $('.pthclass_cart').find('.tariff-card-for-BPO_START_P').find('.sd-wrapper.second-row').addClass('display_none')

    var boostersInCart = $('.boosters-shoping-item').find(".boosters_without_device")
    var boosterWrapperLength = $(boostersInCart).children().length
    if(boosterWrapperLength <= 1) {
        $(boostersInCart).addClass('display_none')
    }

    const allTariffsWrapper = $('.all-tariffs-wrapper')
    const sliders = $(allTariffsWrapper).find('.o-content-slider')

    if(allTariffsWrapper.length > 0) {
        for (var s = 0; s < sliders.length; s++) {
            const item = sliders[s]
            const boosterWrapper = $(item).find('.boosters_without_device')

            handleInitCheckboxes($(boosterWrapper))
        }
    } else {
        const boosterWrapper = $('.boosters_without_device')
        handleInitCheckboxes($(boosterWrapper))
    }

    // Booster With Promo
    const boosterRadioButtons = $('.o-shopping-item__footer').find('.special-booster-radio')
    const jsBoosterPromo =  $('.o-shopping-item__footer').find('.js-booster-promo')


    $(boosterRadioButtons).on('change', function(e) {
        const desiredWrapper = $(this).parent();
        let isSpecificTariff = true;
        if(isSpecificTariff) {
            let elBoostersParent = $(desiredWrapper).parent().parent()
            let elBoostersParentChildrens = $(elBoostersParent).children()
            let childrenArr = []
            for(let c = 0; c < elBoostersParentChildrens.length; c++) {
                let children = elBoostersParentChildrens[c]
                if($(children).hasClass('booster-with-bpo-promo')) {
                    childrenArr.push(children)
                }
            }

            for(let v = 0; v < childrenArr.length; v++) {
                $(childrenArr[v]).find('.booster-mini-prop-wrapper').removeClass('active-booster')
                $(childrenArr[v]).find('.booster-mini-prop-wrapper').find(':radio').next().addClass('special-after-0')
                // $(desiredWrapper).find(':radio').next().removeclass('special-after-0')

            }

            $(desiredWrapper).addClass('active-booster')
            $(desiredWrapper).find(':radio').next().removeClass('special-after-0')
            $(desiredWrapper).find(':radio').next().addClass('special-after-1')

        } else {
            $(desiredWrapper).parent().prev().find('.booster-mini-prop-wrapper').removeClass('active-booster')
            $(desiredWrapper).parent().next().find('.booster-mini-prop-wrapper').removeClass('active-booster')
            $(desiredWrapper).parent().prev().find('.booster-mini-prop-wrapper').find(':radio').next().addClass('special-after-0')
            $(desiredWrapper).parent().next().find('.booster-mini-prop-wrapper').find(':radio').next().addClass('special-after-0')
            $(desiredWrapper).addClass('active-booster')

            $(desiredWrapper).find(':radio').next().removeClass('special-after-0')
            $(desiredWrapper).find(':radio').next().addClass('special-after-1')
        }




        const boosterElement = $(desiredWrapper).parent()

        const dataInfo = getBoosterDataAttributes($(boosterElement), true)
        // here change price for acc if device and booster maybe
        const { device } = virtualCart.getAll()
        //const elem = '.reccacc_'+spring24AccDevices;
        if (document.body.classList.contains('pthclass_cart') && spring24Devices.includes(device.deviceCode)) {
            //console.log(dataInfo, "<----------- dataInfo")
            if (dataInfo.boosterId == spring24Booster ) {
                //console.log(vcHandler._recommendedGadgets, "<----------- _recommendedGadgets")
                vcHandler._recommendedGadgets = vcHandler._recommendedGadgets.map(item=>{
                    if(item.code == spring24AccDevices) {
                        console.log(item.code, "<----------- _recommendedGadgets")
                        if (item.originalPrice === null) {
                            item.originalPrice = item.price;
                        }
                        item.price = 1;
                    }
                })
                vcHandler._recommended = vcHandler._recommended.map(item=>{
                    if(item.code == spring24AccDevices) {
                        console.log(item.code, "<----------- _recommended")
                        if (item.originalPrice === null) {
                            item.originalPrice = item.price;
                        }
                        item.price = 1;
                    }
                })

                vcHandler.setRecommended();
                //virtualCart.setAll()
                //vcHandler.setStickyCart();
            } else {
                //console.log(vcHandler._recommendedGadgets, "<----------- ELSE _recommendedGadgets")

                vcHandler._recommendedGadgets = vcHandler._recommendedGadgets.map(item=>{
                    if(item.code == spring24AccDevices && item.originalPrice !== null) {
                        console.log(item.code, "<----------- ELSE _recommendedGadgets")
                        item.price = item.originalPrice;
                        item.upfront = 0;
                    }
                })
                vcHandler._recommended = vcHandler._recommended.map(item=>{
                    if(item.code == spring24AccDevices && item.originalPrice !== null) {
                        console.log(item.code, "<----------- ELSE _recommended")
                        item.price = item.originalPrice;
                        item.upfront = 0;
                    }
                })
                vcHandler.setRecommended();
                //virtualCart.setAll()
                //vcHandler.setStickyCart();

                // set to display 1 rsd or maybe add old price too
                /*$(elem).find('.accessory-recommended-price span').html('1 RSD');
                $(elem).find('.accessory-recommended-price').attr('data-acc-price','1');*/
            }
        }


        /*$(elem).find('.accessory-recommended-price span').html($(elem).find('.accessory-recommended-price').attr('data-acc-price-remember'));*/


        // const closestAddToCartCTA = $(desiredWrapper).closest('.m-tariff-card').find('.tariff-cta-add-to-cart')
        const closestAddToCartCTA = getClosestAddToCartCTA($(desiredWrapper))

        const firstBoosterCTAPLPLink = getClosestGoToPLPCTA($(desiredWrapper)) //getClosestGoToPLPCTA

        const firstBoosterCTACartModal = getClosestAddToCartCTAChoose($(desiredWrapper))


        // $(firstBoosterCTAPLPLink).attr('data-booster-bpo', dataInfo.boosterBpo)
        // $(firstBoosterCTAPLPLink).attr('data-booster-id', dataInfo.boosterId)
        // $(firstBoosterCTAPLPLink).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
        // $(firstBoosterCTAPLPLink).attr('data-booster-price', dataInfo.boosterPrice)
        // $(firstBoosterCTAPLPLink).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
        // $(firstBoosterCTAPLPLink).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)

        // $(closestAddToCartCTA).attr('data-promo-bpo', dataInfo.boosterBpo)
        $(closestAddToCartCTA).attr('data-booster-bpo', dataInfo.boosterBpo)
        $(closestAddToCartCTA).attr('data-booster-id', dataInfo.boosterId)
        $(closestAddToCartCTA).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
        $(closestAddToCartCTA).attr('data-booster-price', dataInfo.boosterPrice)
        $(closestAddToCartCTA).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
        $(closestAddToCartCTA).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
        $(closestAddToCartCTA).attr('data-booster-title', dataInfo.boosterTitle)

        $(firstBoosterCTACartModal).attr('data-booster-bpo', dataInfo.boosterBpo)
        $(firstBoosterCTACartModal).attr('data-booster-id', dataInfo.boosterId)
        $(firstBoosterCTACartModal).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
        $(firstBoosterCTACartModal).attr('data-booster-price', dataInfo.boosterPrice)
        $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
        $(firstBoosterCTACartModal).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
        $(firstBoosterCTACartModal).attr('data-booster-title', dataInfo.boosterTitle)

        // this is for start tariff page
        if (dataInfo.boosterId === '246') {
            $('.start-single-page-boosters-info-text.junior-booster').addClass('active')
            $('.start-single-page-boosters-info-text.senior-booster').removeClass('active')
        }
        if (dataInfo.boosterId === '247') {
            $('.start-single-page-boosters-info-text.junior-booster').removeClass('active')
            $('.start-single-page-boosters-info-text.senior-booster').addClass('active')
        }




        handleCartChangeRadio(this);

    })

    $(jsBoosterPromo).on('click', function(e) {
        $(this).find('input').trigger('change')
    })

    // Booster Without Promo
    const boosterInputButton = $('.o-shopping-item__footer').find('.special-booster-input')
    const jsBooster = $('.o-shopping-item__footer').find('.js-booster')


    $(boosterInputButton).on('change', function(e) {
        const desiredWrapper = $(this).parent()


        const boosterElement = $(desiredWrapper).parent()
        const dataInfo = getBoosterDataAttributes($(boosterElement), false)

        const closestAddToCartCTA = getClosestAddToCartCTA($(desiredWrapper))

        //console.log(closestAddToCartCTA, "DBGcart 2")
        const firstBoosterCTAPLPLink = getClosestGoToPLPCTA($(desiredWrapper)) //getClosestGoToPLPCTA
        const firstBoosterCTACartModal = getClosestAddToCartCTAChoose($(desiredWrapper))
        const closestFinalPriceWithDevice =  getClosestFinalPriceWithDevice($(desiredWrapper)).children()[0]
        const closestFinalPriceWithoutDevice = getClosestFinalPriceWithoutDevice($(desiredWrapper)).children()[0]

        const closestFinalPriceWithDeviceCart =  getClosestFinalPriceWithDeviceCart($(desiredWrapper)).children()[0]
        const closestFinalPriceWithoutDeviceCart = getClosestFinalPriceWithoutDeviceCart($(desiredWrapper)).children()[0]



        const finalPriceWithDevice = $(closestFinalPriceWithDevice).text().split('.').join('')
        const finalPriceWithoutDevice = $(closestFinalPriceWithoutDevice).text().split('.').join('')
        const finalPriceWithDeviceCart = $(closestFinalPriceWithDeviceCart).text().split('.').join('')
        const finalPriceWithoutDeviceCart = $(closestFinalPriceWithoutDeviceCart).text().split('.').join('')

        if($(desiredWrapper).hasClass('active-booster')) {
            $(this).find('input').attr('checked', false)
            $(desiredWrapper).removeClass('active-booster')
            // $(closestAddToCartCTA).attr('data-promo-bpo', '')
            $(closestAddToCartCTA).attr('data-booster-bpo', '')
            $(closestAddToCartCTA).attr('data-booster-id', '')
            $(closestAddToCartCTA).attr('data-booster-promo-price', '')
            $(closestAddToCartCTA).attr('data-booster-price', '')
            $(closestAddToCartCTA).attr('data-booster-valid-for-SPO', '')
            $(closestAddToCartCTA).attr('data-booster-bpo-promo', '')
            $(closestAddToCartCTA).attr('data-booster-title', '')

            $(firstBoosterCTAPLPLink).attr('data-booster-bpo','')
            $(firstBoosterCTAPLPLink).attr('data-booster-id', '')
            $(firstBoosterCTAPLPLink).attr('data-booster-promo-price', '')
            $(firstBoosterCTAPLPLink).attr('data-booster-price', '')
            $(firstBoosterCTAPLPLink).attr('data-booster-valid-for-SPO', '')
            $(firstBoosterCTAPLPLink).attr('data-booster-bpo-promo', '')
            $(firstBoosterCTAPLPLink).attr('data-booster-title', '')

            $(firstBoosterCTACartModal).attr('data-booster-bpo', '')
            $(firstBoosterCTACartModal).attr('data-booster-id', '')
            $(firstBoosterCTACartModal).attr('data-booster-promo-price', '')
            $(firstBoosterCTACartModal).attr('data-booster-price', '')
            $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO', '')
            $(firstBoosterCTACartModal).attr('data-booster-bpo-promo', '')
            $(firstBoosterCTACartModal).attr('data-booster-title', '')

            const calcFinalPriceWithDevice = parseInt(finalPriceWithDevice) - parseInt(dataInfo.boosterPrice)
            const calcFinalPriceWithoutDevice = parseInt(finalPriceWithoutDevice) - parseInt(dataInfo.boosterPrice)

            const calcFinalPriceWithDeviceCart = parseInt(finalPriceWithDeviceCart) - parseInt(dataInfo.boosterPrice)
            const calcFinalPriceWithoutDeviceCart = parseInt(finalPriceWithoutDeviceCart) - parseInt(dataInfo.boosterPrice)

            $(closestFinalPriceWithDevice).text(formatPrice(calcFinalPriceWithDevice))
            $(closestFinalPriceWithoutDevice).text(formatPrice(calcFinalPriceWithoutDevice))

            $(closestFinalPriceWithDeviceCart).text(formatPrice(calcFinalPriceWithDeviceCart))
            $(closestFinalPriceWithoutDeviceCart).text(formatPrice(calcFinalPriceWithoutDeviceCart))
        } else {
            $(this).find('input').attr('checked', true)
            $(desiredWrapper).addClass('active-booster')


            $(firstBoosterCTAPLPLink).attr('data-booster-bpo', dataInfo.boosterBpo)
            $(firstBoosterCTAPLPLink).attr('data-booster-id', dataInfo.boosterId)
            $(firstBoosterCTAPLPLink).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
            $(firstBoosterCTAPLPLink).attr('data-booster-price', dataInfo.boosterPrice)
            $(firstBoosterCTAPLPLink).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
            $(firstBoosterCTAPLPLink).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
            $(firstBoosterCTAPLPLink).attr('data-booster-title', dataInfo.boosterTitle)


            $(closestAddToCartCTA).attr('data-promo-bpo', dataInfo.boosterBpo)
            $(closestAddToCartCTA).attr('data-booster-bpo', dataInfo.boosterBpo)
            $(closestAddToCartCTA).attr('data-booster-id', dataInfo.boosterId)
            $(closestAddToCartCTA).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
            $(closestAddToCartCTA).attr('data-booster-price', dataInfo.boosterPrice)
            $(closestAddToCartCTA).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
            $(closestAddToCartCTA).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
            $(closestAddToCartCTA).attr('data-booster-title', dataInfo.boosterTitle)

            $(firstBoosterCTACartModal).attr('data-booster-bpo', dataInfo.boosterBpo)
            $(firstBoosterCTACartModal).attr('data-booster-id', dataInfo.boosterId)
            $(firstBoosterCTACartModal).attr('data-booster-promo-price', dataInfo.boosterPromoPrice)
            $(firstBoosterCTACartModal).attr('data-booster-price', dataInfo.boosterPrice)
            $(firstBoosterCTACartModal).attr('data-booster-valid-for-SPO', dataInfo.boosterValidSpo)
            $(firstBoosterCTACartModal).attr('data-booster-bpo-promo', dataInfo.boosterBpoPromo)
            $(firstBoosterCTACartModal).attr('data-booster-title', dataInfo.boosterTitle)

            const calcFinalPriceWithDevice = parseInt(finalPriceWithDevice) + parseInt(dataInfo.boosterPrice)
            const calcFinalPriceWithoutDevice = parseInt(finalPriceWithoutDevice) + parseInt(dataInfo.boosterPrice)

            const calcFinalPriceWithDeviceCart = parseInt(finalPriceWithDeviceCart) + parseInt(dataInfo.boosterPrice)
            const calcFinalPriceWithoutDeviceCart = parseInt(finalPriceWithoutDeviceCart) + parseInt(dataInfo.boosterPrice)

            $(closestFinalPriceWithDevice).text(formatPrice(calcFinalPriceWithDevice))
            $(closestFinalPriceWithoutDevice).text(formatPrice(calcFinalPriceWithoutDevice))

            $(closestFinalPriceWithDeviceCart).text(formatPrice(calcFinalPriceWithDeviceCart))
            $(closestFinalPriceWithoutDeviceCart).text(formatPrice(calcFinalPriceWithoutDeviceCart))

        }


        // const isSelected = !!$(this).find('input').attr('checked');
        handleCartChangeCheckbox(this);
        // console.log($(this).find('input').attr('checked'), 'ovoooo')
        //console.log('8888 booster')
        if (document.body.classList.contains('pthclass_cart')) {
            vcHandler.setAddonsTotalPrice();
        }
    })

    $(jsBooster).on('click', function(e) {
        e.preventDefault()
        $(this).find('input').trigger('change')
    })
    setTimeout(() => {
        setHeightOfBoosters();
    }, 1000)
}
