import Component from '../lib/component.js';
import store from '../store/index.js';

export default class TariffProperties extends Component {

    // Pass our store instance and the HTML element up to the parent Component
    constructor() {
        super({
            store,
            element: document.querySelector('.tariff-properties-wrapper-desktop')
        });
    }

    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        let self = this;
        const { tariff } = store.state;
        const { tariffProperties } = tariff;
        if (!tariffProperties) {
            self.element.innerHTML = '';
            return;
        }


        self.element.innerHTML = `
        ${tariffProperties && tariffProperties !== '0' && tariffProperties.map(property => {
            return `
                <div class="m-04-text-with-icon">
                    <img src="https://cdn.a1.rs/a1website/img/a1/icons/icon-check-black.svg" alt="Check mark" width="14px" height="10px">
                    <span class="a-01-text__primary--sm ">${property}</span>
                </div> 
                `
        }).join('')}
        `;

    }
}
