export default {
    addItem(context, payload) {
        context.commit('addItem', payload); // eslint-disable-line no-undef
    },
    clearItem(context, payload) {
        context.commit('clearItem', payload); // eslint-disable-line no-undef
    },
    setTestString(context, payload) {
        context.commit('setTestString', payload); // eslint-disable-line no-undef
    },
    setTariff(context, payload) {
        context.commit('setTariff', payload); // eslint-disable-line no-undef
    },
    setPaymentType(context, payload) {
        context.commit('setPaymentType', payload); // eslint-disable-line no-undef
    },
    setInstalments(context, payload) {
        context.commit('setInstalments', payload); // eslint-disable-line no-undef
    },
    setParticipation(context, payload) {
        context.commit('setParticipation', payload); // eslint-disable-line no-undef
    },
    setInsurance(context, payload) {
        context.commit('setInsurance', payload); // eslint-disable-line no-undef
    },
    appendAccessory(context, payload) {
        context.commit('appendAccessory', payload); // eslint-disable-line no-undef
    },
    removeAccessory(context, payload) {
        context.commit('removeAccessory', payload); // eslint-disable-line no-undef
    },
    setIsTvOrTable(context, payload) {
        context.commit('setIsTvOrTable', payload); // eslint-disable-line no-undef
    },
    setIsAccessory(context, payload) {
        context.commit('setIsAccessory', payload); // eslint-disable-line no-undef
    },
    setIsBusiness(context, payload) {
        context.commit('setIsBusiness', payload); // eslint-disable-line no-undef
    },
    setIsBusinessAndTablet(context, payload) {
        context.commit('setIsBusinessAndTablet', payload); // eslint-disable-line no-undef
    },
    setTariffCommunicationPromoList(context, payload) {
        context.commit('setTariffCommunicationPromoList', payload); // eslint-disable-line no-undef
    },
};
