// Ovde nam store treba da bismo proverili da li postoji u komponenti vec instanciran
import Store from '../store/store.js';
const languageConsts = {

    add: '{{_("add", "devices", "Dodaj")}}',
    now: '{{ _("now", "common", "odmah") }}',
    addInsurance: '{{_("add_insurance_button", "insurance", "Dodaj osiguranje")}}',
    remove: '{{_("remove", "devices", "Ukloni")}}',
    placanjeNaRate: '{{ _("placanje_na_rate", "pdp", "Plaćanje na rate") }}',
    gotovina: '{{ _("gotovina", "pdp", "Gotovina") }}',
    uzPrviRacun: '{{ _("uz_prvi_racun", "pdp", "Uz prvi račun") }}',
    promoTariffDesc: '{{ _("promo_pdp_tariff_desc", "pdp", "promo_pdp_tariff_desc") }}',
};

export default class Component {
    constructor(props = {}) {
        let self = this;

        this.languageConsts = languageConsts;
        // Setujemo render funkciju na ono sto se nasledi ili je prazna funkcija
        // (Mora da bude funkcija da ne bi pucala greska ako se izostavi iz nekog razloga)
        this.render = this.render || function() {};
        
        // If there's a store passed in, subscribe to the state change
        // Ako je prosledjen store, subscribe-uj se na promenu state-a
        if(props.store instanceof Store) {
            props.store.events.subscribe('stateChange', () => self.render());
        }
        
        // Ovde cuvamo sam html element
        if(props.hasOwnProperty('element')) {
            this.element = props.element || document.createElement('span');
        }
    }
}
