
/**
 * max 1 moze da se doda
 * {
 *     addonType: 'BLA',
 *     addonName: 'Roming 1GB',
 *     addonPrice: 500,
 * }
 */
class TariffAddon {
    constructor(initialState = {}) {
        this.state = {
            id: null,
            bpo: null,
            title: null,
            price: null,
            props: null,
            bpo_valid_for_SPO: null,
            bpo_added_with_tariff: null,
            promo_price: null,
            bpo_promo: null,
            is_booster: false,
        }
        this.setState(initialState);
    }

    setState = (newState) => {
        this.state = newState;
    }

    getStateKeys = () => Object.keys(this.state);

    isEmpty = () => {
        return !Object.values(this.state).filter(item => item).length;
    }

    getAll = () => this.state;

    // setAll = (type, name, price) => {
    //     this._addonType = type;
    //     this._addonTitle = name;
    //     this._addonPrice = price;
    // };

}

export default TariffAddon;
