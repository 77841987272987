import Component from '../lib/component.js';
import store from '../store/index.js';

// import { cleanPrice, numberThousandSeparator } from '../../../utils';

export default class StickyPromoHeader extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.sticky-promo-header-desktop[-removed]') // [-removed] --> remove this if you want promo bar to work again
        });
    }


    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const { tariff } = store.state;
        const { tariffOldPrice, price, name } = tariff;

        if (!this.element) {
            return;
        }

        this.element.innerHTML = `
            <div class="m-bar-structure " id="pdp-overview">
                <div class="m-bar-structure__left">
                    <div class="m-bar-structure__main">

                        <div class="m-bar-structure__text sticky-structure-text">
                            <span class="a-01-text__primary-bold--xs   ">Uz <span>${name}</span></span>
                            <span class="a-01-text__primary-bold--xs   ">
                                <div class="p-tariff-card-addon__price">
                                    <div class="p-tariff-card-addon__price-discount">
                                        <p class="a-01-text__primary--xs lh-large">${tariffOldPrice} RSD</p>
                                    </div>
                                    <div class="p-tariff-card-addon__final-price">
                                        <p class="a-01-text__secondary-bold--sm">${price} RSD</p>
                                    </div>
                                </div>
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
