import Component from '../lib/component.js';
import store from '../store/index.js';

export default class TariffName extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.basket-tariff-label-desktop')
        });
    }

    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const { tariff } = store.state;
        const { name } = tariff;

        let customName = name
        // Old Neo 20 to Neo 23
        /*if(XMASS_2022) {
            if(customName === 'NEO 20') {
                customName = 'NEO 23'
            }
        }*/
        // Neo 23 Feb
        /*if(customName === 'NEO 23') {
            customName = "NEO23"
        }*/

        this.element.innerHTML = `
            ${customName}
        `;
    }
}
