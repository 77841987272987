import Component from '../lib/component.js';
import store from '../store/index.js';

import { numberThousandSeparator } from '../../../utils';

export default class InsurancePriceMonthly extends Component {
    constructor(selector) {
        super({
            store,
            element: document.querySelector(selector)
        });
    }

    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const { insurance, tariff } = store.state;
        const { isSelected, insurancePrice, insurancePromotionActive } = insurance;
        const { tariffCode } = tariff;

        debugLog(insurance, 'insurance');

        let insurancePriceToShow = insurancePrice;
        /*if (tariffCode === 'SPO_SRPP10M') {
            insurancePriceToShow = 0;
        }*/

        debugLog(insurancePromotionActive, 'insurancePromotionActive');
        if (insurancePromotionActive === '1') {
            insurancePriceToShow = 0;
        }

        debugLog(insurancePriceToShow, 'insurancePriceToShow');

        this.element.innerHTML = `${numberThousandSeparator(insurancePriceToShow)}`;

    }
}
