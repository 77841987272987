import Component from '../lib/component.js';
import store from '../store/index.js';

import {cleanPrice, numberThousandSeparator, toggleButton} from '../../../utils';

export default class AccessoriesList extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.accessory-items-wrapper')
        });
    }

    getAccessoryElement = ({code, price, priceCash, commercialName}) => {
        const { paymentType, instalments } = store.state;
        let priceToShow = Math.round(cleanPrice(price) / instalments);
        let showMonthlyString = '/mes';
        let instalmentsToShow = `${instalments} x `;

        if (paymentType === 'gotovina') {
            priceToShow = cleanPrice(priceCash);
            instalmentsToShow = '';
        }

        return `
        <div class="m-bar-structure acc-item-${code}" >
                <div class="m-bar-structure__left">
                    <div class="m-bar-structure__main">
                        <div class="m-bar-structure__image">
                            <a href="#" class="a-image remove-acc-item-from-sticky remove-acc-item-${code}" style="width:10px;height:10px;" data-acc-code="${code}" >
                                <img src="https://cdn.a1.rs/a1website/img/a1/icons/icon-x-red.svg" alt="" />
                            </a>
                        </div>
                        <div class="m-bar-structure__text">
                            <span  class="a-01-text__primary-light--sm "  >${commercialName}</span>
            
                        </div>
                    </div>
                </div>
                <div class="m-bar-structure__right">
                    <div class="m-bar-structure__main">
                        <div class="m-bar-structure__text">
                            <span  class="a-01-text__primary-bold--sm accessory-item-price-single" >${instalmentsToShow}${numberThousandSeparator(priceToShow)} RSD</span>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    toggleAccButtonsOutsideOfSticky = code => {
        const activeAccessoryButton = document.querySelector(`[data-acc-code="${code}"]`);
        if (activeAccessoryButton && activeAccessoryButton.className.indexOf('__inverted') !== -1) {
            toggleButton(activeAccessoryButton); // todo lang consts
        }
    }


    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        if(store.state.accessories.length === 0) {
            this.element.innerHTML = ``;
            return;
        }

        this.element.innerHTML = `
            ${store.state.accessories.map(accessory => this.getAccessoryElement(accessory)).join('')}
        `;

        this.element.querySelectorAll('.remove-acc-item-from-sticky').forEach((removeButton, index) => {
            removeButton.addEventListener('click', e => {
                const clickedAccCode = e.currentTarget.getAttribute('data-acc-code');
                store.dispatch('removeAccessory', { code: clickedAccCode });

                this.toggleAccButtonsOutsideOfSticky(clickedAccCode);
                e.preventDefault();
            });
        });


    }
}
