import Component from '../lib/component.js';
import store from '../store/index.js';


export default class PaymentTypeLabel extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.payment-type-label-desktop')
        });
    }

    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const { paymentType, isTvOrTablet } = store.state;

        if (paymentType === 'rate') {
            this.element.innerHTML = 'Plaćanje na rate';
        } else {
            if (isTvOrTablet) {
                this.element.innerHTML = `Uz prvi račun`;

            } else {
                this.element.innerHTML = 'Gotovina';
            }
        }
    }
}
